/* Side Menu collapse/expand */
function staticlayout() {
  var element1 = document.getElementById("staticlayout");
  element1.classList.toggle("layout-menu-static-inactive");

  var element2 = document.getElementById("menu-button");
  element2.classList.toggle("menu-button-rotate");
}
function hidemask() {
  var element1 = document.getElementById("staticlayout");
  element1.classList.toggle("layout-menu-static-inactive");

  var element2 = document.getElementById("menu-button");
  element2.classList.toggle("menu-button-rotate");
}
/* Side Menu collapse/expand */

